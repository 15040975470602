require('./bootstrap');

import 'bootstrap'

const modal = document.getElementById('modalImagen')
const buttonsMostrarPlantaArquitectonica = document.querySelectorAll('.botonMostrarPlantaArquitectonica')
const buttonsMostrarUbicacion = document.querySelectorAll('.botonMostrarUbicacion')
const buttonsMostrarVideo = document.querySelectorAll('.botonMostrarVideo')
const img = document.getElementById('imgPlantaArquitectonica')
const iframe = document.getElementById('iframeUbicacion')
const tituloIframe = document.getElementById('modalImagenLabel')
const video = document.getElementById('videoPromocional')

for (const button of buttonsMostrarPlantaArquitectonica) {
    button.addEventListener('click', (e) => {
        const boton = e.delegateTarget || e.currentTarget

        iframe.src = ''
        iframe.height = 0
        video.style.display = 'none'

        img.src = boton.dataset.imagenuri
        tituloIframe.innerText = boton.dataset.titulo
    })
}

for (const button of buttonsMostrarUbicacion) {
    button.addEventListener('click', (e) => {
        const boton = e.delegateTarget || e.currentTarget

        img.src = ''
        video.style.display = 'none'

        iframe.height = 400
        iframe.src = boton.dataset.ubicacionurl
        tituloIframe.innerText = boton.dataset.titulo
    })
}

for (const button of buttonsMostrarVideo) {
    button.addEventListener('click', (e) => {
        const boton = e.delegateTarget || e.currentTarget

        iframe.src = ''
        iframe.height = 0
        img.src = ''

        video.style.display = 'block'
        video.src = boton.dataset.video
    })
}

modal.addEventListener('hidden.bs.modal', function (event) {
    video.pause()
})
